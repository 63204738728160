export const TIPS_AND_TRICKS_LIST = {
    welcheNebenkostenBeimKredit: {
        question: "Welche Nebenkosten entstehen bei einem Kredit?",
        answer: "Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten.",
        title: "Welche Nebenkosten entstehen bei einem Kredit?",
        description:
            "Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/welche-nebenkosten-entstehen-bei-einem-kredit/",
    },
    wasBeiImmobilienfinanzierungBeachten: {
        question: "Was muss ich bei der Immobilienfinanzierung beachten?",
        answer: "Ein Überblick: Nur mit einem guten Überblick über deine Finanzen gelangst du zum besten Kredit.",
        title: "Was muss ich bei der Immobilienfinanzierung beachten?",
        description: "Ein Überblick: Nur mit einem guten Überblick über deine Finanzen gelangst du zum besten Kredit.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/was-muss-ich-bei-der-immobilienfinanzierung-beachten/",
    },
    kaufOderMieteGrundsatzfrage: {
        question: "Kauf oder Miete? Die Grundsatzfrage",
        answer: "Ob Kaufen oder Mieten die bessere Wahl ist, hängt ganz von deiner Lebenssituation ab. Manchmal ist ein Kauf allerdings ratsamer.",
        title: "Kauf oder Miete? Die Grundsatzfrage",
        description:
            "Ob Kaufen oder Mieten die bessere Wahl ist, hängt ganz von deiner Lebenssituation ab. Manchmal ist ein Kauf allerdings ratsamer.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/kauf-oder-miete-grundsatzfrage/",
    },
    wasSindZinsen: {
        question: "Was genau sind Zinsen eigentlich?",
        answer: "Ganz grundlegend kannst du dir unter den Zinsen jenen Preis vorstellen, den du dafür zahlst, dass ein:e Kreditgeber:in dir Kapital zur Verfügung stellt.",
        title: "Was genau sind Zinsen eigentlich?",
        description:
            "Wir erklären dir, was Zinsen eigentlich sind und mit unserem Zinsrechner behältst du alle Kosten, rund um deinen Kredit, im Blick.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/was-genau-sind-zinsen-eigentlich/",
    },
    zinsModelle: {
        question: "Welche Zinsmodelle habe ich zur Auswahl? Fixzins vs. variabler Zins",
        answer: "Fixe Zinsen bleiben über eine gewisse Kreditlaufzeit konstant, während sich variable Zinsen laufend ändern können.",
        title: "Welche Zinsmodelle habe ich zur Auswahl? Fixzins vs. variabler Zins",
        description:
            "Fixe Zinsen bleiben über eine gewisse Kreditlaufzeit konstant, während sich variable Zinsen laufend ändern können.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/fixzins-vs-variabler-zins/",
    },
    wasVerstehtManUnterUmschuldung: {
        question: "Was versteht man unter Umschuldung und wie funktioniert es?",
        answer: "Ein unangemessener Zinssatz, zu hohe Raten oder zu kurze Laufzeit. All das sind Gründe für die Umschuldung von Krediten.",
        title: "Was versteht man unter Umschuldung und wie funktioniert es?",
        description:
            "Ein unangemessener Zinssatz, zu hohe Raten oder zu kurze Laufzeit. All das sind Gründe für die Umschuldung von Krediten.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/was-versteht-man-unter-umschuldung/",
    },
    wieVielKredit: {
        question: "Wie viel Kredit kann ich mir leisten?",
        answer: "Wie viel Kredit du dir leisten kannst, hängt vor allem von der Höhe deines monatlichen Einkommens ab.",
        title: "Wie viel Kredit kann ich mir leisten?",
        description:
            "Wie viel Kredit du dir leisten kannst, hängt vor allem von der Höhe deines monatlichen Einkommens ab.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/wie-viel-kredit-kann-ich-mir-leisten/",
    },
    eigenmittel: {
        question: "Welche Möglichkeiten der Eigenmittel gibt es?",
        answer: "Unter Eigenkapital versteht man alle Werte, die ein Kreditnehmer selbst in die Finanzierung einbringt.",
        title: "Welche Möglichkeiten der Eigenmittel gibt es?",
        description:
            "Unter Eigenkapital versteht man alle Werte, die ein Kreditnehmer selbst in die Finanzierung einbringt.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/welche-moeglichkeiten-der-eigenmittel-gibt-es/",
    },
    immobilienbewertungWieFunktioniertDas: {
        question: "Immobilienbewertung - Wie funktioniert das?",
        answer: "Unter Immobilienbewertung versteht man Rechen- und Vergleichsverfahren zur Ermittlung eines realistischen Kaufpreises.",
        title: "Immobilienbewertung - Wie funktioniert das?",
        description:
            "Unter Immobilienbewertung versteht man Rechen- und Vergleichsverfahren zur Ermittlung eines realistischen Kaufpreises.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/immobilienbewertung-wie-funktioniert-das/",
    },
    wasBekommtMiracl: {
        question: "Was bekommt miracl für den Service?",
        answer: "Alle unsere Beratungsleistungen sind für dich kostenlos!",
        title: "Was bekommt miracl für den Service?",
        description: "Alle unsere Beratungsleistungen sind für dich kostenlos!",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/was-bekommt-miracl-fuer-den-service/",
    },
    welcheDokumenteBraucheIch: {
        question: "Welche Dokumente brauche ich für die Beantragung?",
        answer: "Um dir konkrete Details zu deiner Finanzierungsanfrage geben zu können, benötigen wir persönliche Dokumente von dir.",
        title: "Welche Dokumente brauche ich für die Beantragung?",
        description:
            "Um dir konkrete Details zu deiner Finanzierungsanfrage geben zu können, benötigen wir persönliche Dokumente von dir.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/welche-dokumente-brauche-ich/",
    },
    wieSiehtDerNotarTerminAus: {
        question: "Wie sieht der Notar Termin aus bei miracl?",
        answer: "Durch die Zusammenarbeit mit einer Rechtsanwaltskanzlei können wir dir den Service der Kaufvertragserrichtung um nur 1% anbieten.",
        title: "Wie sieht der Notar Termin aus bei miracl?",
        description:
            "Durch die Zusammenarbeit mit einer Rechtsanwaltskanzlei können wir dir den Service der Kaufvertragserrichtung um nur 1% anbieten.",
        icon: "/svg/bulb.svg",
        url: "/gut-zu-wissen/wie-sieht-der-notar-termin-aus/",
    },
};
