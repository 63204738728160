import React from "react";
import styled from "styled-components";

import BulbIcon from "../../assets/icons/bulb.svg";

const TipsCard = ({ data }) => {
    return (
        <Wrapper href={data.url}>
            <IconWrapper>
                <BulbIcon />
            </IconWrapper>
            <h3>{data.question}</h3>
            <ShortAnswer>{data.answer}</ShortAnswer>
        </Wrapper>
    );
};

const ShortAnswer = styled.p`
    max-height: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
`;

const Wrapper = styled.a`
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.12), 0px 4px 10px 3px rgba(17, 24, 39, 0.1);
    color: #4b5563;
    cursor: pointer;
    display: block;
    height: 360px;
    overflow: hidden;
    padding: 40px 26px 20px;
    margin-bottom: 16px;
    position: relative;
    width: 260px;
    z-index: 1;

    &:before {
        background: #f9fafb;
        border-radius: 50%;
        content: "";
        height: 300px;
        left: -72px;
        position: absolute;
        top: -68px;
        width: 300px;
        z-index: -1;
    }

    h3 {
        color: #4b5563;
        font-size: 18px;
        font-weight: 300;
        margin: 36px 0 26px;
    }

    p {
        font-size: 13px;
    }
`;

const IconWrapper = styled.div`
    height: 72px;
`;

export default TipsCard;
